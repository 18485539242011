export const environment = {
  production: true,
  line: 'production', 
  // ystmServiceBaseUrl: 'ystm.eu'
  // ystmServiceBaseUrl: 'https://pd907d6b2.dip0.t-ipconnect.de/c107e3c2f1e7b61f8a54ef648b1d2ec8/services/V10'
  ystmServiceBaseUrl: 'https://yachting-service.de/c107e3c2f1e7b61f8a54ef648b1d2ec8/services/V10',
  mediaGalleryBaseUrl: 'https://pd907d6b2.dip0.t-ipconnect.de/c107e3c2f1e7b61f8a54ef648b1d2ec8/services/V10',
  /**
   * Dynamic feature module disabled
   * - Array<DynamicFeatureName> of feature names disabled in the application in this environment
   */
  //featuresDynamicDisabled: ['MEDIA_GALLERY', 'MEMBER_DIRECTORY']
  featuresDynamicDisabled: ['MEMBER_DIRECTORY'],
  /**
   * The API key for few web services, e.g. to post contact requests
   */
  wsApiKey: `7d3d3c04830611a47e4e80f2a533746bde1615314be9fcf93164a627d3695795`,

};
